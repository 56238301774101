.reportageSellerPriceInput {
  all: unset;
  padding: 12px 0;
}

/* .rmdp-input {
  padding: 16.5px 14px;
} */

a {
  text-decoration: none;
}

.lotteryInput::placeholder{
  font-size: 18px;
  transform: translateY(-4px);
}
